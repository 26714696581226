.input-box {
    width: 100%;
    box-sizing: border-box;
    position: relative;
}
.input-box.focus {

}
.input-box:after,
.input-box:before,
.input-box *{
    box-sizing: inherit
}
.input-label {
    display: block;
    width: 100%;
    position: relative;
}

.input-float {
    position: absolute;
    padding: 2px 8px;
    font-size: 12px;
    border-radius: 4px;
    color: #C5CEDD;
    top: -9px;
    left: 14px;
    transition: 0.3s ease;
    opacity: 0;
    font-weight: normal;
}

.input-float--focus {
    opacity: 1;
    background-color: #fff;
    color: var(--accent-color-primary);
}

.input-field {
    display: block;
    width: 100%;
    -webkit-appearance: none;
    outline: none;
    font-size: 14px;
    padding: 15px 17px;
    -webkit-transition: 0.2s;
    transition: 0.2s;
    border-radius: 4px;
    border: 1px solid #C5CEDD;
    color: #2E384D !important;
    font-style: normal;
    font-weight: normal;
    line-height: 14px;
    margin: 0;
}

.input-field::placeholder {
    font-size: 14px;
    color: #C5CEDD;
}

.input-field:focus {
    border-color: var(--accent-color-primary);
}

.input-box.error .input-field {
    border-color: var(--accent-color-danger)!important;
}
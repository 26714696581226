:root {
    --accent-color-primary: rgb(0, 155, 232);
    --accent-color-primary-hovered: rgb(84, 186, 248);
    --default-transition: 0.3s ease;

    --accent-color-secondary: rgb(252, 161, 58);
    --accent-color-secondary-hovered: rgb(252, 180, 105);
    --accent-color-secondary-light: rgba(252, 180, 105, 0.3);
    --default-bg-color: #fff;
    --default-bg-block: #F5F7FB;

    --text-color-grey: #8798AD;
    --text-color-dark: #2E384D;
    --text-color-extra-dark: #2E384D;

    --checkbox-border-color: #C5CEDD;

    --title-color: #435179;

    --accent-color-success: #00C353;
    --accent-color-success-hovered: #6cf19e;

    --accent-color-danger: #da0d0d;
    --accent-color-danger-hovered: #ee4848;

    --table-head-text-color: #435179;

    --form-els-border-color: #C5CEDD;

    --sidebar-width: 248px;

    --int-field-box-focused-color: var(--accent-color-primary-hovered);
    --int-field-box-error-color: var(--accent-color-danger);

    --int-default-select-schevron-color: #C5CEDD;

    --int-box-border-color: #C5CEDD;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}